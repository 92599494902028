import request, { GraphQLClient, Variables } from "graphql-request";
import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  QueryFunction,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";

const createFetchWithTimeout = (timeout?: number) => async (input: RequestInfo | URL, init?: RequestInit) => {
  if (init?.signal) {
    throw new Error(
      "it looks like graphql-request started using AbortSignal on its own. Please check graphql-request's recent updates"
    );
  }

  const controller = new AbortController();

  const timerId = setTimeout(() => {
    controller.abort();
  }, timeout);

  try {
    return await fetch(input, { ...init, signal: controller.signal });
  } finally {
    clearTimeout(timerId);
  }
};

const client = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_APP_SERVER_URL}/graphql` || "http://localhost:1337/graphql",
  {
    fetch: createFetchWithTimeout(5000)
  }
);

export function usePrefetchGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
): {
  queryKey: [any, any];
  queryFn: QueryFunction<TResult, any[], never>;
} {
  return {
    queryKey: [
      (document?.definitions[0] as any)?.name?.value,
      variables,
    ],
    queryFn: async ({ queryKey }) =>
      client.request(
        document,
        queryKey[1]
      ),
  };
}

export function useGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables
): UseQueryResult<TResult> {
  return useQuery({
    queryKey: [(document?.definitions[0] as any)?.name?.value, variables],
    queryFn: async ({ queryKey }) =>
      client.request(
        document,
        queryKey[1] as Variables | undefined
      ),
  });
}

type ExcludePageParam<T> = "pageParam" extends keyof T
  ? Omit<T, "pageParam">
  : T;

export function useInfiniteGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  ...[variables]: ExcludePageParam<TVariables> extends Record<string, never>
    ? []
    : [ExcludePageParam<TVariables>]
): UseInfiniteQueryResult<TResult> {
  return useInfiniteQuery({
    queryKey: [(document?.definitions[0] as any)?.name?.value, variables],
    queryFn: async ({ queryKey, pageParam }) =>
      request(
        `${process.env.NEXT_PUBLIC_APP_SERVER_URL}/graphql` ||
        "http://localhost:1337/graphql",
        document,
        queryKey[1] ? { ...queryKey[1], pageParam } : { pageParam: pageParam }
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { page, pageCount } = (lastPage as any)[
        (document?.definitions[0] as any)?.selectionSet?.selections[0].name
          .value
      ].meta.pagination;
      return page < pageCount ? page + 1 : undefined;
    },
  });
}
